import { useAxios } from "@vueuse/integrations/useAxios";
import { CartDiscountExtend } from '@/dto/cart/CartDiscountDTO'
import { ClassErrorHandler } from '@/class/ClassErrorHandler'

export class CartDiscountDelete {

  private cartProduct = new CartDiscountExtend()

  constructor(cartProduct: CartDiscountExtend) {
    this.cartProduct = cartProduct
  }

  public async deleteCartProduct() {

    try {
      await this.deleteCartProductApi()

      return true

    } catch (error) {
      new ClassErrorHandler(error)

      return false
    }
  }

  private async deleteCartProductApi() {

    const urlApi = '/cart-discount/' + this.cartProduct.id

    const { data } = await useAxios(urlApi, { method: "DELETE" });

    if (!data.value) {
      throw new Error('Error with cart product id delete')
    }

  }
}
