import { CartDiscountExtend } from '@/dto/cart/CartDiscountDTO';
import { CartDiscountDelete } from '../model/CartDiscountDelete';

export class CartDiscountControllerDelete {
  private cartDiscount: CartDiscountExtend;
  private showToast = false;
  private cartStore = useCartStore();
  private toastStore = useToastStore();

  constructor(cartDiscount: CartDiscountExtend) {
    this.cartDiscount = cartDiscount;
  }

  /**
   * Deletes the discount and updates the cart store.
   */
  public async deleteDiscount(): Promise<void> {
    try {
      await this.deleteCartDiscountFromServer();
      this.removeDiscountFromStore();

      if (this.showToast) {
        this.toastStore.setToastMessage('Réduction supprimée du panier', 'success');
      }
    } catch (error) {
      console.error('Error deleting discount:', error);
      if (this.showToast) {
        this.toastStore.setToastMessage('Erreur lors de la suppression de la réduction', 'error');
      }
    }
  }

  /**
   * Deletes the cart discount from the server.
   */
  private async deleteCartDiscountFromServer(): Promise<void> {
    const cartDiscountDeleter = new CartDiscountDelete(this.cartDiscount);
    await cartDiscountDeleter.deleteCartProduct();
  }

  /**
   * Removes the discount from the cart store.
   */
  private removeDiscountFromStore(): void {
    this.cartStore.removeDiscount(this.cartDiscount);
  }

  /**
   * Enables or disables toast notifications for this operation.
   * @param value - Boolean value indicating whether to show toasts.
   */
  public setShowToast(value: boolean): void {
    this.showToast = value;
  }
}
