import { DiscountCodeDTO } from "~/dto/DiscountCodeDTO";

export class CartDiscountDTO {
  [index: string]: number | string | undefined | DiscountCodeDTO;

  public id = 0;
  public cartId = 0;
  public discountId = 0;
  public studentGenericId = 0;
  public productId = 0;
  public proofPath = "";

  public constructor(init?: Partial<CartDiscountDTO>) {
    if (!init) return;
    for (const [key, value] of Object.entries(init)) {
      if (Object.getOwnPropertyNames(this).includes(key)) {
        this[key] = value;
      }
    }
  }
}

export class CartDiscountExtend extends CartDiscountDTO {
  public discount: DiscountCodeDTO = new DiscountCodeDTO();

  constructor(init?: Partial<CartDiscountExtend>) {
    super();
    if (init === undefined) return;
    Object.assign(this, init);
  }
}
